import React, { Fragment } from "react";
import { Link,useHistory } from "react-router-dom";
import HeaderLogo from "../components/HeaderLogo";

import axiosInstance from '../service/api';  
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from "../components/Loader";

const initialValues = {
    email: '',
    password: '',
    password_confirmation: '',
    otp: '',
    isOtpSent: false,  
};

// Define the validation schema
const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid Email")
        .required("Please enter a valid email"),

    password: Yup.string()
        .when('isOtpSent', {
            is: true,
            then: (schema) => schema.min(8, "Password must be at least 8 characters long").required("Please enter a new password"),
            otherwise: (schema) => schema.notRequired(),
        }),

    password_confirmation: Yup.string()
        .when('isOtpSent', {
            is: true,
            then: (schema) => schema.required("Please enter confirm password").oneOf([Yup.ref("password"), null], "Passwords must match"),
            otherwise: (schema) => schema.notRequired(),
        }),

    otp: Yup.string()
        .when('isOtpSent', {
            is: true,
            then: (schema) => schema.matches(/^\d{6}$/, 'OTP must be exactly 6 digits').required('OTP is required'),
            otherwise: (schema) => schema.notRequired(),
        }),

    isOtpSent: Yup.boolean().default(false) // Add this to ensure Yup knows about the field
});


const Forgot = () => {
    const history = useHistory();
    const handleSubmit = async (values, { setSubmitting, resetForm,setErrors, setFieldValue }) => {
        try {
            if (!values.isOtpSent) {
                // First submission: Send only the email
                const response = await axiosInstance.post('/auth/forgot-password', { email: values.email });
                
                if (response.data.status) {
                    // On success, update the hidden field
                    setFieldValue('isOtpSent', true);
                    showSuccessMsg(response.data.message);
                }
            } else {
                // Second submission: Send the email, OTP, password, and confirm password
                const response = await axiosInstance.post('/auth/reset-password', {
                    email: values.email,
                    otp: values.otp,
                    password: values.password,
                    password_confirmation: values.password_confirmation
                });

                if (response.data.status) {
                    resetForm();
                    showSuccessMsg(response.data.message);
                    history.push('/login');
                }
            }
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data.errors;
                Object.keys(error).map((key) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
            } else {
                showErrorMsg('An unexpected error occurred. Please try again.');
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Fragment>
             <div className="main-wrap bg-image-cover bg-no-repeat vh-100 "
              style={{ backgroundImage: `url("/assets/images/mywow/login-bg.jpeg")` }}
            >
                <div className="row">
                    

                    <div className="container col-xl-6  align-items-center  rounded-3 ">
                        <div className = "row">
                          <div className="col-xl-12 text-center" >
                            <HeaderLogo logoColor="white" />
                          </div>
                         {/*  <div className="col-xl-6 text-right" >
                              <HeaderNav />
                          </div> */}
                        </div>

              <div className="card  border-0  ms-auto me-auto login-card" style = {{boxShadow:`0 4px 12px rgba(0,0,0,0.15)`}}>
              <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 font-lg mb-3">
                                    Change <br />your password
                                </h2>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={forgotPasswordSchema}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={true}
                                    validateOnChange={true}
                                    enableReinitialize={true}
                                >
                                    {({ isSubmitting, values, setFieldValue }) => (
                                        <Form>
                                            <div className="form-group icon-input mb-3">
                                                <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                <Field
                                                    type="text"
                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Your Email Address"
                                                    name="email"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="error-msg pl-2 text-danger"
                                                />
                                            </div>
                                            {values.isOtpSent && (
                                                <>
                                                    <div className="form-group icon-input mb-3">
                                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                        <Field
                                                            type="password"
                                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                            placeholder="Password"
                                                            name="password"
                                                        />
                                                        <ErrorMessage
                                                            name="password"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>

                                                    <div className="form-group icon-input mb-3">
                                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                        <Field
                                                            type="password"
                                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                            placeholder="Confirm Password"
                                                            name="password_confirmation"
                                                        />
                                                        <ErrorMessage
                                                            name="password_confirmation"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>
                                                    
                                                    <div className="form-group icon-input mb-3">
                                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                        <Field
                                                            type="text"
                                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                            placeholder="OTP"
                                                            name="otp"
                                                        />
                                                        <ErrorMessage
                                                            name="otp"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <input type="hidden" name="isOtpSent" value={values.isOtpSent} />
                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1">
                                                    <button
                                                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        {isSubmitting ? <Spinner size="small" /> : 'Submit'}
                                                    </button>
                                                </div>
                                                <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                                    Already have account
                                                    <Link to="/login" className="fw-700 ms-1">
                                                        Login
                                                    </Link>
                                                </h6>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Forgot;
