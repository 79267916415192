import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const authToken = localStorage.getItem('authToken');
    return (
        <Route
            {...rest}
            render={props =>
                authToken && restricted ? (
                    <Redirect to={`${process.env.PUBLIC_URL}/travel-events`} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PublicRoute;
