import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';

import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contactinfo extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <Fragment> 

                <div className="main-wrapper">


                <Header auth = "global" />
                {/* <Leftnav />
                <Rightchat /> */}

                

                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                        
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/contactinformation" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Contact Us</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
                                    

                                        <div className = "row">
                                        <div className="col-lg-8 ">
                                            <p>Life is never ending journey so just keep on travelling, enjoy it and share it.</p><p>We look forward to hearing your thought, opinion, feedback and your critics. We therefore request that you can kindly please reach out <a href="https://mywowtravel.com/">mywowtravel.com</a> by sending us email to <a href="mailto:traveler@mywowtravel.com">traveler@mywowtravel.com</a>, with your feedback we can always improve to perform as your very best traveler social media.</p><p><a href="https://mywowtravel.com/">mywowtravel.com</a> is your wow travel story.</p><p>Best regards,</p><p><a href="/https://mywowtravel.com/">mywowtravel.com</a> community</p>
                                            <h4 class="grey">Leave a Message</h4>

                                    <form action="#">
                                        

                                        <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="w-50 form-group">
                                                            <label className="mont-font fw-600 font-xsss">Name</label>
                                                            <input type="text" name="comment-name" className="form-control" placeholder = "Enter your name" />
                                                </div>        
                                            </div>

                                                    <div className="col-lg-12 mb-3">
                                                        <div className="w-50 form-group">
                                                            <label className="mont-font fw-600 font-xsss">Email</label>
                                                            <input type="text" name="comment-name" className="form-control" placeholder = "Enter your email" />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="w-50 form-group">
                                                            <label className="mont-font fw-600 font-xsss">Phone</label>
                                                            <input type="text" name="comment-name" className="form-control" placeholder = "Enter your phone" />
                                                </div>        
                                            </div>

                                                    <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Pincode</label>
                                                            <textarea style = {{height:"auto"}} row = "5" name="comment-name" className="form-control" ></textarea>
                                                </div>        
                                            </div>
                                        </div>

                                            


                                                <div className="col-lg-12 mb-0 mt-2 ps-0">
                                                    <a href="/contactinformation" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
                                                </div>
                                            </form>
                                            </div>
                                            <div className="col-lg-4">
                                                <h4 class="grey">Reach Us</h4>
                                                    <div class="reach p-2">
                                                        <span class="phone-icon"><i class="feather-phone"></i> +1 (234) 222 0754</span>
                                                </div>        
                                                    <div class="reach p-2">
                                                        <span class="phone-icon">
                                                        <i class="feather-mail"></i> traveler@mywowtravel.com</span>
                                            </div>
                                                    <div class="reach p-2">
                                                        <span class="phone-icon"><i class="feather-map-pin"></i> 228 Park Ave S NY, USA</span>
                                                </div>        
                                                    <ul class="list-inline social-icons">
                                                        <li className = "d-inline-block p-2"><a href="/"><i class="text-grey-900 feather-facebook"></i></a></li>
                                                        <li className = "d-inline-block p-2"><a href="/"><i class="text-grey-900 feather-twitter"></i></a></li>
                                                        <li className = "d-inline-block p-2 "><a href="/"><i class="text-grey-900 feather-linkedin"></i></a></li>
                                                    </ul>
                                            </div>
                                            <br />

                                        </div><div className = "row mt-5">
                                        <div className="col-lg-12 p-0">
                                                <div className="rounded-3 overflow-hidden mb-2" style={{ height: '250px', width: '100%' }}>
                                                <GoogleMapReact
                                                defaultCenter={this.props.center}
                                                defaultZoom={this.props.zoom}
                                                >
                                                <AnyReactComponent
                                                    lat={59.955413}
                                                    lng={30.337844}
                                                    text="My Marker"
                                                />
                                                </GoogleMapReact>
                                            </div>
                                            </div></div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>         
            </Fragment>
        );
    }
}

export default Contactinfo;