import React, { useState } from 'react';
import {  useLoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places']; // Load the places library



const options = {
  fields: ['formatted_address', 'geometry', 'name'], // Specify what you want from the API
};

const AddressAutocomplete = ({ onPlaceSelected,setFieldValue }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use your API key here
    libraries,
  });

  const [autocomplete, setAutocomplete] = useState(null);
  //const [inputValue, setInputValue] = useState("");

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      console.log(place,"autocomplete address");
      setFieldValue('check_in_address',place.formatted_address); 
      onPlaceSelected(place); // Pass the selected place to the parent component
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} options={options}>
        
      {/* <form action="#" className="ms-auto header-search ms-3">
            <div className="form-group mb-0 icon-input">
                <i className="feather-search font-sm text-grey-400"></i>
                <input type="text" placeholder="Where are you?" className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w-100 theme-dark-bg" />
            </div>
        </form> */}
        <>
        <div className="form-group mb-0 icon-input">
        <i className="feather-search font-sm text-grey-400"></i>
                <input 
                    type="text" 
                    name="check_in_address"
                    placeholder="Where are you?" 
                    className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w-100 theme-dark-bg"
                    /* value={inputValue} */
                    onChange={(e) => setFieldValue('check_in_address',e.target.value)}
                
                />
                </div>
        {/* <input
          type="text"
          placeholder="Enter a location"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ width: "100%", padding: "10px" }}
        /> */}
        </>
      </Autocomplete>
    </div>
  );
};

export default AddressAutocomplete;
