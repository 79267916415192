import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import './main.scss';
import 'react-toastify/dist/ReactToastify.css';



import Home from './pages/Home';

import Badge from './pages/Badge';
import Group from './pages/Group';
import Storie from './pages/Storie';
import Member from './pages/Member';
import Email from './pages/Email';
import Emailopen from './pages/Emailopen';
import Settings from './pages/Settings';
import Account from './pages/Account';
import Contactinfo from './pages/Contactinfo';
import Socialaccount from './pages/Socialaccount';
import Password from './pages/Password';
import Payment from './pages/Payment';
import Notification from './pages/Notification';
import Helpbox from './pages/Helpbox';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot from './pages/Forgot';
import ResetPassword from './pages/ResetPassword';

import Notfound from './pages/Notfound';

import ShopOne from './pages/ShopOne';
import ShopTwo from './pages/ShopTwo';
import ShopThree from './pages/ShopThree';
import Singleproduct from './pages/Singleproduct';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Chat from './pages/Chat';
import Live from './pages/Live';
import Job from './pages/Job';
import Event from './pages/Event';
import Hotel from './pages/Hotel';
import Videos from './pages/Videos';
import Comingsoon from './pages/Comingsoon';


import Grouppage from './pages/Grouppage';
import Userpage from './pages/Userpage';
import Authorpage from './pages/Authorpage';
import Hotelsingle from './pages/Hotelsingle';
import Analytics from './pages/Analytics';

import {  BrowserRouter, Switch  } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';

import PrivateRoute from './Routes/PrivateRoute';
import PublicRoute from './Routes/PublicRoute';
import { UserProvider } from './context/UserContext';
import AuthCallback from './components/AuthCallBack';
import TermsandConditions from './pages/TermsandConditions';

class Root extends Component{
    
  render(){
    /* const isAuthenticated = () => {
        return !!localStorage.getItem('authToken');
    }; */
      return(
        <UserProvider>
        <ToastContainer />
          <BrowserRouter basename={'/'}>
              <Switch>
                    {/* public Routes */}
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} restricted={false}/>
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/contactinformation`} component={Contactinfo} restricted={false}/>
                    
                    {/* Routes will be accessible only till user not logged in */}
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/login`} component={Login} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/register`} component={Register} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/forgot`} component={Forgot} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/auth/google/callback`} component={AuthCallback} restricted={true} />
                    <PublicRoute exact path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsandConditions}/>
                    


                    {/* Private Routes will be accessible only after user is logged in */}
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/travel-events`} component={Home}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/travel-smart`} component={Home}/>

                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/images`} component={Home}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/videos`} component={Home}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/accountinformation`} component={Account}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/user-details`} component={Grouppage}/>
                   
                    
                    

                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultbadge`} component={Badge}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultgroup`} component={Group}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultstorie`} component={Storie}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultemailbox`} component={Email}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultemailopen`} component={Emailopen}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultsettings`} component={Settings}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultvideo`} component={Videos}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultanalytics`} component={Analytics}/>
                    
                    
                    
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/members`} component={Member}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/friends`} component={Member}/>
                    


                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/socialaccount`} component={Socialaccount}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/password`} component={Password}/>
                    
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/payment`} component={Payment}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultnoti`} component={Notification}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/helpbox`} component={Helpbox}/>
                   
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/notfound`} component={Notfound}/>

                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/shop1`} component={ShopOne}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/shop2`} component={ShopTwo}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/shop3`} component={ShopThree}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/singleproduct`} component={Singleproduct}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/messages`} component={Chat}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultlive`} component={Live}/>       
                    
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultjob`} component={Job}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaultevent`} component={Event}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaulthotel`} component={Hotel}/>  
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/grouppage`} component={Grouppage}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/userpage`} component={Userpage}/>
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/authorpage`} component={Authorpage}/>  
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>  
                    <PrivateRoute exact path={`${process.env.PUBLIC_URL}/defaulthoteldetails`} component={Hotelsingle}/>

                    
              </Switch>
          </BrowserRouter>
          </UserProvider>
      )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();