import React, { useContext,useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import AddressAutocomplete from './AddressAutocomplete';

import ModalComponent from "./ModalComponent";

import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import axiosInstance,{handleImageCompression} from '../service/api';

import { UserContext } from '../context/UserContext';
import ReactPlayer from 'react-player';

const userPostSchema = Yup.object().shape({
    post_content: Yup.string()
      .min(1, "Description should at least have 1 character."),
    
    check_in_address: Yup.string()
      .min(1, "Check-In address should at least have 1 character"),
  
    // Custom validation to ensure at least one field is filled
  }).test('one-of-required', 'Either post content or check-in address is required.', function(value) {
    return value.post_content || value.check_in_address;
  });

  const initPost = {
    'post_content':'',
    'check_in_address':''
  };

  



const Createpost = (props) => {
    
    const { user } = useContext(UserContext);
    const [isCheckinOpen,setIsCheckinOpen] = useState(false);
    const [imagePreview,setImagePreview] = useState(null);
    const [videoPreview,setVideoPreview] = useState(null);
    
    const createUserPost = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            const response = await axiosInstance.post('posts/create-post', values);
            showSuccessMsg(response.data.message);
            resetForm();
            props.onPostCreated(); 
            setImagePreview(null);
            setVideoPreview(null);
            return { success: true }; // Return a success response
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
              return { success: false }; // Return a failed response
        } finally {
            setSubmitting(false);
        }
    };

    // File Upload 
    const  fileUpload = ({form, field, fileType}) => {
        
        return (
        <input type="file" className="imageUploadData" id="imageUploadData" onChange={(event) => handleFileUpload(event, form.setFieldValue,field,fileType)} />
        )
    }


    const textArea = (props) => {
  
        let { form, field, className, placeholder } = props;
        return (
          <textarea
            className={className}
            rows="4"
            cols="30"
            placeholder={placeholder}
            name={field.name}
            onChange={(values) =>
              form.setFieldValue(field.name, values.target.value)
            }
            value={field.value}
            
          />
        );
      };

      const handleFileUpload = async (event, setFieldValue,field, fileType = 'image') => {
        const {  isUploadingImage } = props;
        if (isUploadingImage) return;
        
        let file = event.target.files[0];
        if (fileType === 'image') {
            file = await handleImageCompression(event.target.files[0]); //event.target.files[0];
        }else{
            file =  event.target.files[0];
        }
        

        if (fileType === 'image' && !file.type.startsWith('image/')) {
            showErrorMsg('Please upload a valid image file.');
            return;
        }

        if (fileType === 'video' && !file.type.startsWith('video/')) {
            showErrorMsg('Please upload a valid video file.');
            return;
        }


        var formdata = new FormData();
        formdata.append("type", "post");
        formdata.append("files[0]", file, file.name);
        
        try {
            const response = await axiosInstance.post('file-manager/upload', formdata);
            const res = response.data;
            if (response.data.status) {
                const  files  = res.data;
                const image = files[0].file.url; 
                setFieldValue(field.name, `${image}`);
                showSuccessMsg(response.data.message);
                if(fileType === 'image') setImagePreview(image);

                if(fileType === 'video') setVideoPreview(image); 

              } else {
                const error    = res.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
        
              }
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                console.log(errors); 
                showErrorMsg('An unexpected error occurred. Please try again. image upload')
              }
        } finally {
            
        }
    
        
      };
      const handleCheckinModel = ()=>{
            setIsCheckinOpen(!isCheckinOpen);
      }

      const handlePlaceSelected = (place) => {
        console.log('Selected place:', place);
        // Handle the selected place here (e.g., save to state, send to API, etc.)
      };
      
      

         return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <Formik
                    initialValues={initPost}
                    validationSchema={userPostSchema}
                    onSubmit={createUserPost} 
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validateOnChange={true}
                    innerRef={props.formRef} // Attach the ref here
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                       {/*  <div className="card-body p-0">
                            <div className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Create Post</div>
                        </div> */}
                        {props.isCheckinModal && <AddressAutocomplete setFieldValue = {setFieldValue} onPlaceSelected={handlePlaceSelected} /> }
                        <div className="card-body p-0 mt-2 position-relative">
                            <figure className="avatar position-absolute ms-2 mt-1 top-5">
                                
                                <img 
                                    src={user && user.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${user.profile_image}`:"assets/images/user.png"} 
                                    alt="user" 
                                    className="shadow-sm rounded-circle w30 h30" />
                            </figure>
                            <Field
                                type="textarea"
                                name="post_content"
                                component={textArea}
                                className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 text-dark  font-xsss fw-500 border-light-md theme-dark-bg"
                               
                            />
                            <ErrorMessage
                                name="post_content"
                                component="div"
                                className="text-danger"
                            />   
                        </div>

                        <div className="card-body d-flex p-0 mt-0">
                              <label className="cursorPointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" data-tip data-for="addvideo">
                                  <Field as="file" className="imageUploadData" name="post_videos" component={fileUpload} fileType="video" />
                                  <i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Video</span>
                              </label>
                              <ReactTooltip id="addvideo" place="bottom" effect="solid">
                              Add Video
                              </ReactTooltip> 
                              <label className="cursorPointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" data-tip data-for="addpicture">
                                  <Field as="file" className="imageUploadData" name="post_image" component={fileUpload} fileType="image" />
                                  <i className="font-md text-success feather-image me-2"></i> <span className="d-none-xs">Photo</span>
                              </label>
                              <ReactTooltip id="addpicture" place="bottom" effect="solid">
                              Add Picture
                              </ReactTooltip>
                              {!props.isCheckinModal && 
                                <><div onClick = {handleCheckinModel} className="pointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning feather-map-pin  me-2"></i><span className="d-none-xs">Check-In </span></div>
                              
                                  <div className="ms-auto pointer"  >
                                      <button
                                          type="submit"
                                          className="border-0 p-2 lh-20 w100 bg-black text-white text-center font-xssss fw-600 ls-1 rounded-xl d-inline-block"
                                          disabled={isSubmitting}
                                      >
                                          Post
                                          {isSubmitting && (
                                              <span className="spinner-border spinner-border-sm ml-2"></span>
                                          )}
                                      </button>
                                  </div>
                                </>
                           }
                        </div>
                        
                          <div className="card-body d-flex p-0 mt-2">
                            {imagePreview && 
                                <img 
                                  src={`${process.env.REACT_APP_WEB_BASE_URL}${imagePreview}`} 
                                  alt="post pic"    
                                  className="img-fluid rounded-3 me-2 w150 h100" />
                            }
                            {videoPreview && (
                              <ReactPlayer
                                  controls
                                  width="150px"
                                  height="100px"
                                  className="react-player"
                                  url={`${process.env.REACT_APP_WEB_BASE_URL}${videoPreview}`}
                              />
                            )}
                          </div>
                        

                  
                    {/* <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                        
                    </div> */}
                  
                </Form>
            )}
          </Formik>
            {
                (isCheckinOpen && <ModalComponent onPostCreate={props.onPostCreated} onClose={handleCheckinModel} />
            )}
          
            </div>
        );
    
}

export default Createpost;