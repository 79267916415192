// src/axiosInstance.js
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../constants/apiConstants';
import imageCompression from 'browser-image-compression';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        //useHistory().push('/login');
        window.location.href = '/login'; 
      }
      return Promise.reject(error);
    }
  );

// Reusable function to fetch posts with pagination
export const getPosts = async (page = 1, limit = 10) => {
    try {

      const userId = getQueryParam('userId'); 

     const pathname = window.location.pathname.split('/').filter(Boolean).pop();
      const response = await axiosInstance.get(`/user/posts?page=${page}&per_page=${limit}`,
        {params: {post_type:pathname,user_id:userId}}
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  };


  export const getQueryParam = (key) => {
      const params = new URLSearchParams(window.location.search);
      return params.get(key);
  };


  export async function handleImageCompression(imageFile) {
    const file = imageFile;
  
    const options = {
        maxSizeMB: 1,          // Maximum size in MB
        maxWidthOrHeight: 1920, // This won't resize as long as the dimensions are larger
        useWebWorker: true,     // Use multi-threading for faster compression
        maxIteration: 10,       // Limits the number of iterations for compression
        initialQuality: 0.8     // Initial quality value
    };
  
    try {
        const compressedFile = await imageCompression(file, options);
        console.log('Compressed file size:', compressedFile.size / 1024 / 1024, 'MB');
        return compressedFile; 
        // Now you can upload the compressed file to your API
        // uploadFileToApi(compressedFile);
    } catch (error) {
        console.error('Error compressing the image:', error);
    }
  }

export default axiosInstance;
