


export const timeAgo = (postDate) => {
  const now = new Date();
  const secondsPast = Math.floor((now - new Date(postDate)) / 1000);

  if (secondsPast < 60) {
    return `${secondsPast} second${secondsPast !== 1 ? 's' : ''} ago`;
  } else if (secondsPast < 3600) {
    const minutesPast = Math.floor(secondsPast / 60);
    return `${minutesPast} minute${minutesPast !== 1 ? 's' : ''} ago`;
  } else if (secondsPast < 86400) {
    const hoursPast = Math.floor(secondsPast / 3600);
    return `${hoursPast} hour${hoursPast !== 1 ? 's' : ''} ago`;
  } else {
    const daysPast = Math.floor(secondsPast / 86400);
    if (daysPast <= 30) {
      return `${daysPast} day${daysPast !== 1 ? 's' : ''} ago`;
    } else {
      const postDateObj = new Date(postDate);
      const day = postDateObj.getDate();
      const month = postDateObj.toLocaleString('default', { month: 'short' });
      const year = postDateObj.getFullYear();
      return `${month} ${day}, ${year}`;
    }
  }
}


