import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const authToken = localStorage.getItem('authToken');
    return (
        <Route
            {...rest}
            render={props =>
                authToken ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                )
            }
        />
    );
};

export default PrivateRoute;
