import React from 'react'
import {  Link  } from "react-router-dom";

function HeaderLogo(props) {
  
  return (
    <div className="mt-2  bg-transparent shadow-none border-0">
        <div className="nav-top w-100" style = {{justifyContent : 'left'}}>
            <Link  to="/">
                <img src = "/assets/images/my-wow-logo.png" alt = "mywow logo" />
                {/* <span className={`d-inline-block fredoka-font ls-3  text-${logoColor} font-xss logo-text mb-0 ms-1`}>Sharing travel story and/or promoting travel business. </span>  */}
            </Link>
            
        </div>
    </div>
  )
}

export default HeaderLogo