import React, {  Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';

import { Formik,Form, Field,ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../service/api'; 
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import Spinner from "../components/Loader";

const initialValues = {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
};

const updatePasswordValidationScheme = Yup.object().shape({
    current_password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter a current password"),
    new_password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter a new password"),
    new_password_confirmation: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    try {
        const response = await axiosInstance.put('update-user-password', values);
        showSuccessMsg(response.data.message);
        resetForm();
    } catch (errors) {
        if (errors.response && errors.response.data) {
            let error = errors.response.data.error;
            showErrorMsg(error);
          } else {
            showErrorMsg('An unexpected error occurred. Please try again.')
          }
    } finally {
        setSubmitting(false);
    }
};

const Password = () => {
        return (
            <Fragment> 

            <div className="main-wrapper">

                <Header />
                <Leftnav />
                <Rightchat />


                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Change Password</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <Formik
                                initialValues={initialValues}
                                validationSchema={updatePasswordValidationScheme}
                                onSubmit={handleSubmit}
                                validateOnBlur={true}
                                validateOnChange={true}
                            >
                                {({ isSubmitting, errors }) => (
                                    <Form>
                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">Current Password</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Current Password"
                                                            name="current_password"
                                                        />
                                                        <ErrorMessage
                                                            name="current_password"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>        
                                                </div>

                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">New Password</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="New Password"
                                                            name="new_password"
                                                        />
                                                        <ErrorMessage
                                                            name="new_password"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>        
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">Confirm Password</label>
                                                        <Field
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Confirm Password"
                                                            name="new_password_confirmation"
                                                        />
                                                        <ErrorMessage
                                                            name="new_password_confirmation"
                                                            component="div"
                                                            className="error-msg pl-2 text-danger"
                                                        />
                                                    </div>        
                                                </div>                                     
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 mb-0">
                                                    
                                                    <button
                                                        className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        {isSubmitting ? <Spinner size="small" /> : 'Save'}
                                                    </button>
                                                </div>
                                            </div>

                                            
                                            </Form>
                                )}
                            </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>            
                </div>
            </div>

            </Fragment>
        );
}

export default Password;