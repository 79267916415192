import React from "react";
import HeaderLogo from "../components/HeaderLogo";
//import HeaderNav from "../components/HeaderNav";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from "../components/Loader";
import { API_BASE_URL } from '../constants/apiConstants'; 
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

const Register = () => {
    const initialValues = {
        fullname: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        agree: false,
    };

    const userSignUpSchema = Yup.object().shape({
        fullname: Yup.string()
            .min(2, 'Name must be at least 2 characters long')
            .max(50, 'Name must be less than 50 characters long')
            .required("Please enter your name"),
        username: Yup.string().required("Please enter your username"),
        email: Yup.string()
            .email("Invalid Email")
            .required("Please enter a valid email"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .required("Please enter a password"),
        confirm_password: Yup.string()
            .required("Please confirm password")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        agree: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/auth/register`, values);
            showSuccessMsg(response.data.message);
            resetForm();
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
             <div className="main-wrap bg-image-cover bg-no-repeat vh-100 "
              style={{ backgroundImage: `url("/assets/images/mywow/login-bg.jpeg")` }}
            >
                <div className="row">
                    

                    <div className="container col-xl-6  align-items-center  rounded-3 ">
                        <div className = "row">
                          <div className="col-xl-12 text-center" >
                            <HeaderLogo logoColor="white" />
                          </div>
                         {/*  <div className="col-xl-6 text-right" >
                              <HeaderNav />
                          </div> */}
                        </div>

              <div className="card  border-0  ms-auto me-auto login-card" style = {{boxShadow:`0 4px 12px rgba(0,0,0,0.15)`}}>
              <div className="card-body rounded-0 text-left">
                            <h2 className="fw-700 font-lg mb-3">
                                Create <br />your account
                            </h2>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={userSignUpSchema}
                                onSubmit={handleSubmit}
                                validateOnBlur={true}
                                validateOnChange={true}
                            >
                                {({ isSubmitting, errors }) => (
                                    <Form>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <Field
                                                type="text"
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                placeholder="Your Name"
                                                name="fullname"
                                            />
                                            <ErrorMessage
                                                name="fullname"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                            <Field
                                                type="text"
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                placeholder="Username"
                                                name="username"
                                            />
                                            <ErrorMessage
                                                name="username"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <Field
                                                type="text"
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                placeholder="Your Email Address"
                                                name="email"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <Field
                                                type="password"
                                                className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                placeholder="Password"
                                                name="password"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        <div className="form-group icon-input mb-1">
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            <Field
                                                type="password"
                                                className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                placeholder="Confirm Password"
                                                name="confirm_password"
                                            />
                                            <ErrorMessage
                                                name="confirm_password"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <Field
                                                type="checkbox"
                                                className="form-check-input mt-2"
                                                id="agree"
                                                name="agree"
                                            />
                                            <label
                                                htmlFor="agree"
                                                className="form-check-label font-xsss text-grey-500"
                                            >
                                                Accept Terms and Conditions <a target="_blank" rel="noopener noreferrer" class="textDecorationNone" href="terms-and-conditions">Terms of use</a>
                                            </label>
                                            <ErrorMessage
                                                name="agree"
                                                component="div"
                                                className="error-msg pl-2 text-danger"
                                            />
                                        </div>
                                        {errors.api && <div className="error">{errors.api}</div>}
                                        <div className="col-sm-12 p-0 text-left">
                                            <div className="form-group mb-1">
                                                <button
                                                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                >
                                                    {isSubmitting ? <Spinner size="small" /> : 'Register'}
                                                </button>
                                            </div>
                                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                                Already have an account?{' '}
                                                <Link to="/login" className="fw-700">
                                                    Login
                                                </Link>
                                            </h6>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Register;
