import React, { useState,useEffect,Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardOne from '../components/ProfilecardOne';


import ListPosts from "./ListPosts";
import axiosInstance from '../service/api';
import { useLocation,useHistory } from 'react-router-dom';


const Grouppage = () => {

    const history = useHistory();

    const [userDetail, setUserDetail] = useState({});
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const userId = query.get('userId');

    const fetchUserDetails = async () => {
        try {
          const response = await axiosInstance.get(`/get-user-details/${userId}`); 
          //const {user:{data}} = response; `/user/posts?page=${page}&per_page=${limit}`
          const { data:{user} } = response.data;
          
          setUserDetail(user);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
    
      useEffect(() => {
        if(!userId){
            history.goBack(); 
        }
        fetchUserDetails();
        // eslint-disable-next-line
      }, [userId]);

    
   
        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <ProfilecardOne userDetails = {userDetail} fetchUserDetails = {fetchUserDetails} />
                                    <Profiledetail 
                                        userDetails = {userDetail}
                                          />
                                    <Profilephoto userId = {userId} />
                                 </div>
                                <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                                    <ListPosts />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter /> 

            </Fragment>
        );
    }

export default Grouppage;