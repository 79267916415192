import React, { useRef } from "react";





import Createpost from "./Createpost";




const ModalComponent = (props) => {
     const formRef = useRef(null); // Create a ref

    const handleSubmit = async () => {
        if (formRef.current) {
            const response = await formRef.current.submitForm(); 
            if (response && response.success) {
               props.onClose();
            } 
            
        }
    };

    return (
        <div className={`checkin-model d-block `}>
            <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                <div className="modal-popup-header w-100 border-bottom">
                    <div className="card p-3 d-block border-0 d-block">
                        <h5 className="fw-700 text-black font-xs mt-1 mb-1">Create Post</h5>
                        
                       <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer"  onClick={props.onClose} ><i className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                    </div>
                </div>
                <div className="modal-popup-body w-100  h-auto">

                <Createpost onPostCreated={props.onPostCreate} isCheckinModal={true} formRef={formRef} />
                
               
                </div>
                <div className="modal-popup-footer w-100 border-top">
                    <div className="card p-3 d-block border-0 d-block">
                        <button
                            type="submit"
                            className="border-0 p-2 lh-20 w-100 bg-black me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl d-inline-block"
                            onClick={handleSubmit}
                        >
                            Post
                            
                        </button>
                    </div>

                    

                </div>
                
            </div> 
        </div>
)
}
export default ModalComponent; 