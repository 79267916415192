import React, {useState} from "react";
import HeaderLogo from "../components/HeaderLogo";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from "../components/Loader";
import { API_BASE_URL } from '../constants/apiConstants'; 
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

import GoogleLoginButton from "../components/GoogleLoginButton";
//import FacebookLoginButton from "../components/FacebookLoginButton";


// Validation schema
const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

const Login = () => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
          const response = await axios.post(`${API_BASE_URL}/auth/login`, values);
           if(response.data.status){

                showSuccessMsg(response.data.message);
                const { data:{auth:{access_token}} } = response.data;
                localStorage.setItem('authToken', access_token);
                window.location = '/travel-events';
            }
          // Store the token in localStorage
          
    
          // Show success message
          
    
          // Redirect to the dashboard or another page
          // Assuming you use react-router-dom for navigation
          // this.props.history.push('/dashboard');
        } catch (error) {
          if (error.response && error.response.data) {
            
            showErrorMsg(error.response.data.error);
            //setErrors({ api: error.response.data.message });
          } else {
            showErrorMsg('An unexpected error occurred. Please try again.');
            //setErrors({ api: 'An unexpected error occurred. Please try again.' });
          }
        } finally {
          setSubmitting(false);
        }
      };
    return (
        <React.Fragment>
            <div className="main-wrap bg-image-cover bg-no-repeat vh-100 "
              style={{ backgroundImage: `url("/assets/images/mywow/login-bg.jpeg")` }}
            >
                <div className="row">
                    

                    <div className="container col-xl-6  align-items-center  rounded-3 ">
                        <div className = "row">
                          <div className="col-xl-12 text-center" >
                            <HeaderLogo logoColor="white" />
                          </div>
                         {/*  <div className="col-xl-6 text-right" >
                              <HeaderNav />
                          </div> */}
                        </div>

              <div className="card  border-0  ms-auto me-auto login-card" style = {{boxShadow:`0 4px 12px rgba(0,0,0,0.15)`}}>
                  <div className="card-body rounded-0 text-left">
                    
                      <h2 className="fw-700 font-lg mb-3">
                          Login into <br />your account
                      </h2>
                                
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, errors }) => (
                    <Form>
                      <div className="form-group icon-input mb-3">
                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                        <Field
                          type="text"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Your Email Address"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-msg pl-2 text-danger"
                        />
                      </div>
                      <div className="form-group icon-input mb-1">
                        <Field
                          type={isPasswordVisible ? 'text' : 'password'}
                          className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                          placeholder="Password"
                          name="password"
                        />
                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                        <i 
                          onClick = {togglePasswordVisibility} 
                          /* className="pointer font-sm ti-eye text-grey-500 pe-0 right-position line-through" */
                          className={`pointer font-sm ti-eye ${isPasswordVisible ? 'text-grey-500' : 'text-grey-500 line-through'} pe-0 right-position`}
                          ></i>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-msg pl-2 text-danger"
                        />
                      </div>
                      <div className="form-check text-left mb-3">
                        <Field
                          type="checkbox"
                          className="form-check-input mt-2"
                          name="remember"
                        />
                        <label className="form-check-label font-xsss text-grey-500">
                          Remember me
                        </label>
                        <Link
                          to="/forgot"
                          className="fw-600 font-xsss text-grey-700 mt-1 float-right"
                        >
                          Forgot your Password?
                        </Link>
                      </div>

                      {errors.api && <div className="error">{errors.api}</div>}

                      <div className="col-sm-12 p-0 text-left">
                        <div className="form-group mb-1">
                          <button
                            className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            {isSubmitting ? <Spinner size="small" /> : 'Login'}
                          </button>
                         
                        </div>
                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                          Don't have an account
                          <Link to="/register" className="fw-700 ms-1">
                            Register
                          </Link>
                        </h6>
                      </div>
                      <div className="col-sm-12 p-0 text-center mt-2">
                        <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">
                          Or, Sign in with your social account
                        </h6>
                        <div className="form-group mb-1">
                          
                          {/* <a
                            href="/register"
                            className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"
                          >
                            <img
                              src="assets/images/icon-1.png"
                              alt="icon"
                              className="ms-2 w40 mb-1 me-5"
                            />
                            Sign in with Google
                          </a> */}
                          <GoogleLoginButton />
                        </div>
                        <div className="form-group mb-1">
                          
                          <a
                            href="/register"
                            className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0"
                          >
                            <img
                              src="assets/images/icon-3.png"
                              alt="icon"
                              className="ms-2 w40 mb-1 me-5"
                            />
                            Sign in with Facebook
                          </a>
                         {/*  <FacebookLoginButton /> */}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
