import React from 'react';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri =  process.env.REACT_APP_GOOGLE_REDIRECT_URI;
const scope = 'profile email';

const GoogleLoginButton = () => {
  const handleLogin = () => {
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const authUrl = `${oauth2Endpoint}?client_id=${googleClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
    window.location.href = authUrl;
  };

  return (
    <>
        <button 
            className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"
            onClick={handleLogin}
            type = "button"
            >
            <img
                src="assets/images/icon-1.png"
                alt="icon"
                className="ms-2 w40 mb-1 me-5"
            />
            Sign in with Google
        </button>
    </>

           
  )

};

export default GoogleLoginButton;
