import React, { Component  } from "react";
import {  Link  } from "react-router-dom";
import Header from '../components/Header';






class AboutUs extends Component {
    render() {
        return (

            <div className="main-wrapper">
                <Header auth = "global" />
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link  className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">About Us</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-75 border-0 m-auto">
                                        <div className = "w-50 m-auto">
                                            <img src = "/assets/images/logo.png" alt = "mywow logo" />
                                            <span style = {{color : "#555",lineHeight :"3"}}  className={`d-block fredoka-font lh-  text- font-xxl logo-text mb-0 ms-1`}>Sharing travel story and/or promoting travel business. </span> 
                                        </div>
                                        <p className = "font-xs lh-5" >Mywowtravel.com is the first platform made for Traveler’s and designed by travelers to interact socially with others, sharing stories and showing off their adventures, while still in tunes with friends from all parts of the globe. It allowed users, to update stories on their homepage, with a touch of citizen journalism.</p>
                                        <p className = "font-xs lh-5">You can write your own Wow travel experience at anytime, anywhere and at any place. What you see, what you do, what you eat, what you buy and even you can promote your local tourism will be our community’s heartbeat. It’s all about Wowing your travel experience in your own language.</p>
                                    </div>
                                </div>
                            
                        
                        
                    </div>            
                </div>

            </div>

            
        );
    }
}

export default AboutUs;