import React, {useState,useEffect} from 'react';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import axiosInstance from '../service/api';


import { Link } from 'react-router-dom';
import CommentField from './CommentField';
import Load from '../components/Load';
import ReactTooltip from "react-tooltip";
  



const PostComment = ({ postId, replyToComment, setReplyToComment, updatePost }) => {
    const [comments, setComments] = useState([]); 
    

    
    


    const [commentsLoading, setCommentsLoading] = useState(true);
    
    const [hasMoreComments, setHasMoreComments] = useState(true);
    //const [postId, setPostId] = useState([]);

    const [openReplyBox, setOpenReplyBox] = useState(null);

    const toggleReplyBox = (commentId) => {
        setOpenReplyBox(openReplyBox === commentId ? null : commentId);
    };

    const fetchCommentsList = async () => {
        setCommentsLoading(true);
        try {
            const response = await axiosInstance.get(`posts/get-comments/${postId}`,);
            setComments(response.data.data);
            setHasMoreComments(response.data.data.length > 0);

        } catch (error) {
            console.error('Error fetching comment list', error);
        }finally {
            setCommentsLoading(false);
        }
    };

    useEffect(() => {
        fetchCommentsList();   
        // eslint-disable-next-line
    }, [postId]);
    
    const handleCommentCreated = (postData) => {
        fetchCommentsList();
        updatePost(postData);
    }

    const commentLike = (event, comment_id,like_type) => {
        event.preventDefault();
        axiosInstance.post('posts/like-comment', { comment_id,like_type })
          .then(response => {
            fetchCommentsList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                console.log(errors); 
                let error = errors.response.data.errors;
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

    
         return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 pt-2 pb-3 ">
                <CommentField type = "comment" postId={postId} onCommentCreated={handleCommentCreated} />
                {comments.map((comment) => (
                    <div className="card-body p-0 d-flex mt-2" key={`commentbox${comment.id}`}>
                        
                        <ProfileImageBox cuserDetails={comment.user}  />
                        
                        <div className="w-100">
                            <div className='bg-commentbox px-2'>
                                <Link className = "float-left" to={`user-details?userId=${comment.user && comment.user.id}`}>
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                        {comment.user && comment.user.name}
                                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"></span>
                                    </h4>
                                </Link>
                                <div className="float-right ms-auto pointer">
                                    <i className="ti-more-alt text-grey-900 bg-greylight font-xss"></i>
                                </div>
                                <div className="clearfix"></div>
                                <p className="fw-500 text-dark lh-26 font-xssss w-100 mb-2">
                                    {comment.comment_content}
                                </p>
                            </div>
                            <div className="card-body d-flex p-0  mt-2">
                                
                                
                                <label data-tip data-for="likeButtons">
                                    {comment.like_count>0 && comment.like_count}
                                    &nbsp; <i  
                                        onClick={(event) => commentLike(event, comment.id, 'like')} 
                                        className={`pointer feather-thumbs-up text-white ${comment.like_count > 0 ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}
                                    ></i>
                                </label>
                                <ReactTooltip id="likeButtons" place="top" effect="solid">
                                    Like
                                </ReactTooltip> 
                                <label data-tip data-for="loveButtonIcons">
                                    {comment.love_count>0 && comment.love_count} 
                                        &nbsp; <i 
                                        onClick={(event) => commentLike(event,comment.id,'love')} 
                                        className={`pointer feather-heart text-white ${comment.love_count > 0 ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}></i>
                                </label>
                                <ReactTooltip id="loveButtonIcons" place="top" effect="solid">
                                    Love
                                </ReactTooltip>
                                <label  onClick={(event) => commentLike(event,comment.id,'wow')} data-tip data-for="wowButtonIcons" className = "pointer  me-2 ">
                                    {comment.wow_count>0 && comment.wow_count} &nbsp;<span className={`fw-700  font-xssss ${comment.wow_count > 0? 'text-orange':'text-grey-900'}`}>
                                        Wow!
                                    </span>
                                </label>
                                <ReactTooltip id="wowButtonIcons" place="top" effect="solid">
                                    Wow
                                </ReactTooltip>
                                
                                
                                
                                {/* <a onClick = {(event)=>commentLike(event,comment.id)}>
                                    <h4 className={`pointer fw-700 ${comment.user_liked?'text-orange':'text-grey-900'} font-xssss mt-1 mb-1`}>
                                        {comment.like_count>0 && comment.like_count} Like
                                    </h4>
                                </a> */}
                                
                                <span className="d-block font-xs mx-2 lh-3 text-grey-500">|</span>
                                <label 
                                    onClick={(event)=>toggleReplyBox(comment.id)}  
                                    className="pointer"
                                >
                                    <span className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                        Reply
                                    </span>
                                </label>
                                <label>
                                    <span className="d-block mt-1 mx-2 font-xsss">
                                        {comment.replies.length > 0 && `${comment.replies.length} ${comment.replies.length > 1 ? 'Replies' : 'Reply'}`}
                                    </span>
                                </label>
                            </div>


                            {/* Reply Box */}
                            {comment.replies && comment.replies.map((reply) => (
                                <div className='card-body d-flex p-0  mt-2' key={`reply${reply.id}`} >
                                    <ProfileImageBox cuserDetails={reply.user}  />
                                    <div className='w-100'>
                                        <div className='bg-commentbox px-2'>
                                            <Link className = "float-left" to={`user-details?userId=${reply.user && reply.user.id}`}>
                                                <h4 className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                                    {reply.user && reply.user.name}
                                                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"></span>
                                                </h4>
                                            </Link>
                                            <div className="float-right ms-auto pointer">
                                                <i className="ti-more-alt text-grey-900 bg-greylight font-xss"></i>
                                            </div>
                                            <div className="clearfix"></div>
                                            <p className="fw-500 text-dark lh-26 font-xssss w-100 mb-2">
                                                {reply.comment_content}
                                            </p>
                                        </div>
                                        <div className="card-body d-flex p-0  mt-2">
                                
                                
                                        <label data-tip data-for="likeButtons">
                                            {reply.like_count>0 && reply.like_count}
                                            &nbsp; <i  
                                                onClick={(event) => commentLike(event, reply.id, 'like')} 
                                                className={`pointer feather-thumbs-up text-white ${reply.like_count > 0 ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}
                                            ></i>
                                        </label>
                                        <ReactTooltip id="likeButtons" place="top" effect="solid">
                                            Like
                                        </ReactTooltip> 
                                        <label data-tip data-for="loveButtonIcons">
                                            {reply.love_count>0 && reply.love_count} 
                                                &nbsp; <i 
                                                onClick={(event) => commentLike(event,reply.id,'love')} 
                                                className={`pointer feather-heart text-white ${reply.love_count > 0 ? 'bg-red-gradiant' : 'bg-grey-faded'} me-1 btn-round-xs font-xss`}></i>
                                        </label>
                                        <ReactTooltip id="loveButtonIcons" place="top" effect="solid">
                                            Love
                                        </ReactTooltip>
                                        <label  onClick={(event) => commentLike(event,reply.id,'wow')} data-tip data-for="wowButtonIcons" className = "pointer  me-2 ">
                                            {reply.wow_count>0 && reply.wow_count} &nbsp;<span className={`fw-700  font-xssss ${reply.wow_count > 0? 'text-orange':'text-grey-900'}`}>
                                                Wow!
                                            </span>
                                        </label>
                                        <ReactTooltip id="wowButtonIcons" place="top" effect="solid">
                                            Wow
                                        </ReactTooltip>
                                        
                                        
                                        
                                        
                                        
                                        <span className="d-block font-xs mx-2 lh-3 text-grey-500">|</span>
                                        <label 
                                            onClick={(event)=>toggleReplyBox(comment.id)}  
                                            className="pointer"
                                        >
                                            <span className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                                Reply
                                            </span>
                                        </label>
                                        
                                    </div>
                                    </div>

                                    
                                </div>
                            ))}

                                            
                            {openReplyBox === comment.id && (
                                <CommentField 
                                type = "reply"
                                onCommentCreated={handleCommentCreated}
                                postId={postId} parentId = {comment.id} />
                            )}

                        </div>
                    </div>

                ))}

                {commentsLoading && <Load />}

                {!hasMoreComments && <p>No more comments to load</p>}
                        
                        

                
            </div>
        );
    
}


const ProfileImageBox = (props) => {
    const { cuserDetails } = props;
    return (
        <Link  to={`user-details?userId=${cuserDetails && cuserDetails.id}`}>
            <figure className="avatar me-2">
                <img
                    src={cuserDetails && cuserDetails.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${cuserDetails.profile_image}`:"assets/images/user.png"} 
                    alt="avater"
                    className="shadow-sm rounded-circle w45 h45"
                />
            </figure>
        </Link>
    ); 
}


export default PostComment;

export { ProfileImageBox }; 