// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';

import axiosInstance from '../service/api'; 
import { ACCESS_TOKEN_NAME } from '../constants/apiConstants';
const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try 
            {
                const token = localStorage.getItem(ACCESS_TOKEN_NAME);
                if (!token) {
                  return; 
                }
                const response = await axiosInstance.get('auth/me');
                const { data:{user} } = response.data;
                setUser(user);
            } catch (error) {
                console.error('Failed to fetch user details', error);
            }
        };

        fetchUserDetails();
    }, []);

    // Function to update the user details in the context
    const updateUser = (newUserDetails) => {
        setUser(newUserDetails); // Update the user state
    };

    return (
        <UserContext.Provider value={{ user, setUser,updateUser }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
