import React, { useEffect, useState, Fragment,useContext } from "react";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance  from '../service/api';
import {handleImageCompression } from '../service/api'; // Ensure this is the correct import path

import Header from '../components/Header';

import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import Spinner from "../components/Loader";

import { UserContext } from '../context/UserContext';



const Account = () => {
  const { updateUser } = useContext(UserContext);
  const [user, setUser] = useState({});
  
  const [imagePreviewUrl,setImagePreviewUrl] = useState(null);

  const [isUploading,setIsUploading] = useState(false);

  const [coverImagePreviewUrl,setCoverImagePreviewUrl] = useState(null);

  const [isCoverImageUploading,setIsCoverImageUploading] = useState(false);

  const fetchUserDetails = async () => {
    try {
      const response = await axiosInstance.get('/auth/me'); // Adjust the endpoint
      //const {user:{data}} = response;
      const { data:{user} } = response.data;
      
      setUser(user);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  

  useEffect(() => {
    

    fetchUserDetails();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phonenumber: Yup.string().required('Phone is required'),
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    postcode: Yup.string().required('Postcode is required'),
    about: Yup.string(),
    
  });

  const validate = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (error) {
      if (error.inner && error.inner.length > 0) {

        // Scroll to the first invalid field
        const firstInvalidField = error.inner[0].path;
        const invalidFieldElement = document.querySelector(`[name="${firstInvalidField}"]`);
        
        if (invalidFieldElement) {
          invalidFieldElement.scrollIntoView({ behavior: 'smooth' });
          invalidFieldElement.focus();
        }

        error.inner.forEach((err) => {
          showErrorMsg(err.message);
        });
      } else {
        showErrorMsg(error.message);
      }
      return error.errors.reduce((acc, curr) => {
        acc[curr.path] = curr;
        return acc;
      }, {});
    }
  };


  const formik = useFormik({
    initialValues: {
      name: user.name || '',     
      email: user.email || '',
      phonenumber: user.phonenumber || '',
      country: user.country || '',
      address: user.address || '',
      city: user.city || '',
      postcode: user.postcode || '',
      profile_image: user.profile_image || '',
      about:user.about || '',
      
    },
    validateOnBlur: false,    // Disable validation on blur
    validateOnChange: false,  // Disable validation on change
    enableReinitialize: true,
    validationSchema,

    validate,
    
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await axiosInstance.put('/update-user-profile', values); // Adjust the endpoint
        showSuccessMsg(response.data.message);

        fetchUserDetails();
        updateUser(response.data.data);
        //alert('User details updated successfully!');
        //history.push('/accountinformation');
      } catch (errors) {
        let error = errors.response.data.error;
        Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
      }finally {
        setSubmitting(false); // Set isSubmitting to false after submission
        setIsUploading(false);
        setImagePreviewUrl(null);
        setCoverImagePreviewUrl(null);
        setIsCoverImageUploading(false);
      }
    },
  });

  

    // Function to handle profile image upload
    const handleProfileImageUpload = async (event,image_type) => {
        const file = event.target.files[0];

        const cfile = await handleImageCompression(file); 
        console.log(cfile); 
        /* let status = false; 
        if(status){
          return false; 
        } */

        const formData = new FormData();
        formData.append("type", "profile"); // Adjust as per your backend requirements
        formData.append("files[0]", cfile,cfile.name);
        if(image_type === 'profile_image'){
          setIsUploading(true);
        }
        if(image_type === 'cover_photo'){
          setIsCoverImageUploading(true);
        }

        try {
            const response = await axiosInstance.post('file-manager/upload', formData);
            const res = response.data;
            if (response.data.status) {
                const  files  = res.data;
                const image = files[0].file.url; 
                showSuccessMsg(response.data.message);
                 
                if(image_type === 'profile_image'){
                  setImagePreviewUrl(image);
                  formik.setFieldValue('profile_image', `${image}`);
                }

                if(image_type === 'cover_photo'){
                  setCoverImagePreviewUrl(image); 
                  formik.setFieldValue('cover_photo', `${image}`);
                }
                

              } else {
                const error    = res.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
        
              }

        } catch (error) {
        // Handle network errors or other exceptions
        console.error('Error uploading image:', error);
        } finally {
          if (image_type === 'profile_image') {
            setIsUploading(false);
          } else if (image_type === 'cover_photo') {
            setIsCoverImageUploading(false);
          }
        }

  };
   
  return (
    <Fragment>
      <Header />
      {/* <Leftnav />
      <Rightchat /> */}

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Account Details</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <Link to={`user-details?userId=${user && user.id}`}>
                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                          
                          {user.profile_image ?
                              <img src={`${process.env.REACT_APP_WEB_BASE_URL}${user.profile_image}`} alt="avatar" className="shadow-sm rounded-3 w-100" />
                          : <img src="https://via.placeholder.com/300x300.png" alt="avatar" className="shadow-sm rounded-3 w-100" />}
                        </figure>
                      </Link>
                      <Link to={`user-details?userId=${user && user.id}`}>
                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{user.name && user.name }</h2>
                      </Link>
                      <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">{user.country && user.country }</h4>
                    </div>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>

                      
                    </div>

                    <div className="row">
                      

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phonenumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phonenumber}
                          />
                          {formik.touched.phonenumber && formik.errors.phonenumber ? (
                            <div className="text-danger error">{formik.errors.phonenumber}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="text-danger error">{formik.errors.country}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger error">{formik.errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Town / City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger error">{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Postcode</label>
                          <input
                            type="text"
                            className="form-control"
                            name="postcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.postcode}
                          />
                          {formik.touched.postcode && formik.errors.postcode ? (
                            <div className="text-danger error">{formik.errors.postcode}</div>
                          ) : null}
                        </div>
                      </div>

                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">Profile Image</label>
                            <input type="file" name="profileImage" id="profileImage" className="input-file" onChange={(event) => handleProfileImageUpload(event, 'profile_image')} />
                            <div className="row m-0 justify-content-center rounded-3 text-center bg-white btn-tertiary  p-4 w-100 border-dashed">
                              
                              
                              {isUploading ? <Spinner size="small" /> : (
                                <div className="col-lg-7 text-center"><label htmlFor="profileImage" className="js-labelFile">
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">Drag and drop or click to replace</span>
                                </label>
                                </div>
                              )}

                              {imagePreviewUrl ? <div className="col-lg-3 text-center">
                                <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                    <img src={`${process.env.REACT_APP_WEB_BASE_URL}${imagePreviewUrl}`} alt="avatar" className="shadow-sm rounded-3 w-100" />
                                </figure>
                                
                              </div> : '' }
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">Cover Photo</label>
                            <input type="file" name="cover_photo" id="cover_photo" className="input-file" onChange={(event) => handleProfileImageUpload(event, 'cover_photo')} />
                            <div className="row m-0 justify-content-center rounded-3 text-center bg-white btn-tertiary  p-4 w-100 border-dashed">
                   
                              {isCoverImageUploading ? <Spinner size="small" /> : (
                                <div className="col-lg-7 text-center"><label htmlFor="cover_photo" className="js-labelFile">
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">Drag and drop or click to replace</span>
                                </label>
                                </div>
                              )}

                              {coverImagePreviewUrl ? <div className="col-lg-3 text-center">
                                <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                    <img src={`${process.env.REACT_APP_WEB_BASE_URL}${coverImagePreviewUrl}`} alt="avatar" className="shadow-sm rounded-3 w-100" />
                                </figure>
                                
                              </div> : '' }
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">About</label>
                        <textarea 
                        name="about" 
                        className="form-control mb-0 p-3 h100 bg-greylight lh-16" 
                        rows="5" 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value = {formik.values.about}
                        ></textarea>
                    </div>
                      
                      <div className="col-lg-12">
                        {/* <button type="submit" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">
                          Save {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm ml-2"></span>
                                    )}
                        </button> */}

                        <button
                          type="submit"
                          className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                          disabled={formik.isSubmitting} // Disable button when submitting
                        >
                          {formik.isSubmitting ? <Spinner size="small" /> : 'Save'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Account;
