import React, { useState, useEffect } from 'react';
import axiosInstance from '../service/api';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

const Friends = () => {
    const [friendList, setFriendList] = useState([]);
    useEffect(() => {
        fetchFriendList();
    }, []);

    const fetchFriendList = async () => {
        try {
            
            const response = await axiosInstance.get('get-friend-requests');
            //console.log()
            setFriendList(response.data?.data ?? []);
        } catch (error) {
            console.error('Error fetching member list', error);
        }
    };

    const acceptConnection = (event, connection_id) => {
        event.preventDefault();
        axiosInstance.post('accept-connection', { id:connection_id })
          .then(response => {
            // Handle successful response
            fetchFriendList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                console.log(error.connection_id); 
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

      const calcelConnection = (event, connection_id,self) => {
        event.preventDefault();
        axiosInstance.post('cancel-connection-request', { id:connection_id,self })
          .then(response => {
            // Handle successful response
            fetchFriendList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Friend Request</h4>
                    <a href="/friends" className="fw-600 ms-auto font-xssss text-dark">See all</a>
                </div>
                {friendList.map((value , index) => (
                <div className="wrap" key={index}>
                    <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                        <figure className="avatar me-3">
                            <img src={value.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${value.profile_image}`:"assets/images/user.png"} alt="user" className="float-right p-0 bg-white rounded-circle w75 h75 shadow-xss" />
                        </figure>
                        <h4 className="fw-700 text-grey-900 font-xssss mt-1">{value.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.friend} mutual friends</span></h4>
                    </div>
                    <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                        <a 
                            onClick={(event) => acceptConnection(event,value.id)} 
                            href="/" 
                            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                            >Confirm</a>
                        <a 
                            onClick={(event) => calcelConnection(event,value.id,false)} 
                            href="/"
                            className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl"
                            >Delete</a>
                    </div>
                </div>

                ))}

                
            </div>
        );
}

export default Friends;