import React from "react";

import HeaderLogo from '../components/HeaderLogo';




const TermsandConditions = () => {

        return (
            <React.Fragment>
            <div className="main-wrap bg-image-cover bg-no-repeat "
              style={{ backgroundImage: `url("/assets/images/mywow/login-bg.jpeg")` }}
            >
                <div className="row">
                   <div className="container col-xl-6  align-items-center  rounded-3 ">
                        <div className = "row">
                          <div className="col-xl-12 text-center" >
                            <HeaderLogo logoColor="white" />
                          </div>
                        </div>
                        <div className = "row">
                          <div className="pt-3 col-xl-12 text-center" >
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center" 
                                    style={{backgroundImage: `url("assets/images/help-bg.png")`}}>
                                        <div className="card-body p-4 text-center" style={{backgroundColor:'rgba(0,85,255,0.8)'}}>
                                            <h2 className="fw-700 text-white m-0 lh-2">Terms and Conditions for Social Media Platform</h2>
                                            
                                        </div>
                                    </div>
                                    <div className="card text-left p-2 border-0  mt-2" style = {{boxShadow:`0 4px 12px rgba(0,0,0,0.15)`}}>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">1. Acceptance of Terms:</h5>
                                        <p className = "lh-3 text-black fs-5">
                                            By accessing or using our social media platform, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you must not use the platform. We reserve the right to update or modify these terms at any time without prior notice. Continued use of the platform constitutes acceptance of the revised terms.
                                        </p>
                                        
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">2. Eligibility:</h5>
                                        <p className = "lh-3 text-black fs-5">
                                            You must be at least 13 years old (or the minimum legal age in your jurisdiction) to use this platform. By using the platform, you represent and warrant that you meet the eligibility requirements and have the legal capacity to enter into this agreement.
                                        </p>

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">3. User Conduct:</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Users are expected to interact respectfully with others on the platform. You agree not to:
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Post, upload, or distribute any content that is unlawful, harmful, defamatory, abusive, harassing, violent, or otherwise objectionable.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Engage in any activity that violates any applicable laws, regulations, or the rights of others.
                                            Use the platform to distribute unsolicited promotional or commercial content (spam), or any other form of solicitation.
                                        </p>
                                        <p className = "lh-3 text-black fs-5">
                                            Engage in any form of data mining, scraping, or unauthorized access to the platform's systems.
                                        </p>

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">4. Content Ownership and Licenses:</h5>
                                        <p className = "lh-3 text-black fs-5">
                                        <b>User Content:</b> You retain ownership of the content you post on the platform. However, by posting content, you grant the platform a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, modify, adapt, publish, distribute, and display such content in connection with the operation and promotion of the platform.
Platform Content: All content provided by the platform, including but not limited to text, images, graphics, logos, and software, is the property of the platform or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, modify, or distribute any content from the platform without prior written consent.

                                        </p>

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">
                                            5. Privacy and Data Protection:
                                        </h5>
                                        <p className = "lh-3 text-black fs-5">
                                            Your privacy is important to us. Our Privacy Policy explains how we collect, use, and share your personal information. By using the platform, you consent to the collection and use of your information as outlined in the Privacy Policy. We employ industry-standard security measures to protect your data, but we cannot guarantee complete security.
                                        </p>

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">6. Account Security and Responsibilities:</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials (username and password) and are fully responsible for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security. We are not liable for any loss or damage arising from your failure to protect your account information.
                                        </p>
                                        <p className = "lh-3 text-black fs-5">
                                            <b>Account Hijacking:</b> We are not responsible for any account hijacking incidents that may result from phishing, social engineering, malware, or other unauthorized activities. It is your responsibility to take appropriate precautions to safeguard your account.

                                        </p>
                                        

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">7. Intellectual Property Infringement:</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>User Responsibility:</b> Users are solely responsible for ensuring that the content they post does not infringe on the intellectual property rights of others. This includes, but is not limited to, copyright, trademark, patent, and trade secret rights.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Platform Liability:</b> We are not responsible for any intellectual property theft, infringement, or duplicacy of content posted by users. If you believe that your intellectual property rights have been violated, please contact us with detailed information, and we will investigate the matter in accordance with applicable laws.
                                        </p>

                                        <h5 className="fw-600 pt-2 pb-2 mb-0">8. Data Leakage and Security:</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Data Leakage:</b> While we take reasonable measures to protect your data, we cannot guarantee the security of information transmitted over the internet or stored on our systems. We are not liable for any data leakage, loss, or theft that occurs as a result of unauthorized access, cyber-attacks, or other security breaches.
                                        </p>
                                        <p className = "lh-3 text-black fs-5">
                                            <b>Data Backup:</b> Users are responsible for maintaining backup copies of their content. We are not responsible for any loss of data resulting from system failures, account closures, or other interruptions in service.
                                        </p>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">9. Limitation of Liability:</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            To the fullest extent permitted by law, the platform, its affiliates, officers, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Your access to or use of, or inability to access or use, the platform.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            Any conduct or content of any third party on the platform, including defamatory, offensive, or illegal conduct.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">Any content obtained from the platform.</p>
                                        <p className = "mb-1 lh-3 text-black fs-5">Unauthorized access, use, or alteration of your transmissions or content.</p>
                                        <p className = "lh-3 text-black fs-5">Intellectual property theft, account hijacking, or any data leakage resulting from security breaches.</p>


                                        <h5 className="fw-600 pt-2 pb-2 mb-0">10. Indemnification:</h5>
                                        <p className = "lh-3 text-black fs-5">
                                            You agree to indemnify, defend, and hold harmless the platform, its affiliates, officers, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or related to:<br />
                                            Your use of the platform. <br />
                                            Your violation of these Terms and Conditions.<br />
                                            Your violation of any third-party rights, including intellectual property rights.<br />
                                            Any content you post or share on the platform.<br />
                                            Any data breaches or security incidents involving your account.

                                        
                                        </p>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">
                                            11. Governing Law and Dispute Resolution:
                                        </h5>
                                        <p className = "lh-3 text-black fs-5">
                                            These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction where the platform is based, without regard to its conflict of law principles. Any disputes arising from or relating to these terms shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association (or similar arbitration body), and the arbitration shall be conducted in the jurisdiction where the platform is headquartered.
                                        </p>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">12. Severability:
                                        </h5>
                                        <p className = "lh-3 text-black fs-5">
                                        If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will remain in full force and effect.

                                        </p>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">13. Entire Agreement:
                                        </h5>
                                        <p className = "lh-3 text-black fs-5">
                                        These Terms and Conditions, together with the Privacy Policy and any other legal notices published by the platform, constitute the entire agreement between you and the platform regarding the use of the platform and supersede all prior agreements between you and the platform.
                                        </p>
                                        <h5 className="fw-600 pt-2 pb-2 mb-0">Disclaimer</h5>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>General Disclaimer:</b> The platform and its content are provided "as is" without any warranties, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the platform will meet your requirements or that its operation will be uninterrupted, secure, or error-free.
                                        </p>
                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Third-Party Content:</b> The platform may include content provided by third parties or links to third-party websites. We do not control, endorse, or assume responsibility for any third-party content or websites. Interactions with third-party websites are at your own risk, and we recommend reviewing the terms and privacy policies of any third-party sites you visit.
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>No Professional Advice:</b> The information provided on the platform is for general informational purposes only and should not be considered professional, legal, or financial advice. Always seek the advice of qualified professionals for specific matters. We are not liable for any actions taken based on the information provided on the platform.
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Limitation of Liability:</b> To the maximum extent permitted by law, we shall not be liable for any damages, including but not limited to direct, indirect, incidental, special, consequential, or punitive damages, arising out of or related to your use of the platform, regardless of the cause of action
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Intellectual Property Infringement:</b> We are not responsible for any intellectual property theft or infringement that occurs as a result of user-generated content. Users are solely responsible for ensuring that their content complies with applicable intellectual property laws.
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Account Security:</b> We are not liable for any damages resulting from account hijacking, unauthorized access, or any other security breaches related to your account. It is your responsibility to safeguard your account credentials and take appropriate security measures.
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Data Leakage:</b> We are not responsible for any data leakage or loss resulting from unauthorized access, cyber-attacks, or other security incidents. Users should take appropriate precautions to protect their data and maintain backups of important information.
                                        </p>

                                        <p className = "mb-1 lh-3 text-black fs-5">
                                            <b>Jurisdictional Disclaimer:</b> Some jurisdictions do not allow the exclusion of certain warranties or the limitation of liability for incidental or consequential damages. In such jurisdictions, our liability is limited to the fullest extent permitted by law.
                                        </p>
                                    </div>
                                
                           
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
}

export default TermsandConditions;