import React,{useState,useEffect,useCallback} from 'react';
import axiosInstance from '../service/api';
import { Link } from 'react-router-dom';
const contactList = [
    {
        imageUrl: 'user.png',
        name: 'Armany Seary',
        friend: '45',
    },
    {
        imageUrl: 'user.png',
        name: 'Mohannad Zitoun',
        friend: '18',
    },
    {
        imageUrl: 'user.png',
        name: 'Hurin Seary',
        friend: '28',
    },
]



const Contacts = () => {

    const [contactList, setContactList] = useState([]);
    

    const fetchContactList = useCallback(async () => {
        try {
            
            const response = await axiosInstance.get('get-available-users',{
                params: {  friends:true  } 
            });
            setContactList(response.data.data.data);
        } catch (error) {
            console.error('Error fetching contact list', error);
        }
    }, []);

    

    useEffect(() => {
        fetchContactList();
    }, [fetchContactList]);

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Confirm Friend</h4>
                    <a href="/friends" className="fw-600 ms-auto font-xssss text-black">See all</a>
                </div>
                {contactList.map((value , index) => (

                <div key={index} className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3">
                    <figure className="avatar me-2 mb-0">
                        {/* <img src={`assets/images/${value.profile_image	}`} alt="avater" className="shadow-sm rounded-circle w45" /> */}
                        <img src={value.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${value.profile_image}`:"assets/images/user.png"} alt="avater" className="shadow-sm rounded-circle w45 h45" />
                    </figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.name} {/* <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.friend} mutual friends</span> */}</h4>
                    <Link className="btn-round-sm bg-white ms-auto mt-2" to = {`user-details?userId=${value.id}`}>
                    <span className="feather-chevron-right font-xss text-grey-900"></span>
                    </Link>
                </div>

                ))}

                
            </div>
        );
}

export default Contacts;