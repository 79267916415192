import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling


const toastConfig = {
	position: toast.POSITION.TOP_RIGHT,
	autoClose: 2500
};

const showToast = (type, message) => {
	switch (type) {
		case 'success':
			toast.success(message, toastConfig);
			break;
		case 'error':
			toast.error(message, toastConfig);
			break;
		case 'warning':
			toast.warn(message, toastConfig);
			break;
		default:
			toast.info(message, toastConfig);
	}
};

export const showSuccessMsg = msg => showToast('success', msg);

export const showErrorMsg = msg => showToast('error', msg);

export const showWarningMsg = msg => showToast('warning', msg);
