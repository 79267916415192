
import React from 'react';
import axiosInstance from '../service/api';
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

const UserActionButton = ({connection_status,userId,fetchMemberList}) =>  {
    const addMember = (event, connection_id) => {
        event.preventDefault();
        axiosInstance.post('create-connection', { connection_id })
          .then(response => {
            // Handle successful response
            fetchMemberList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                console.log(error.connection_id); 
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

      const acceptConnection = (event, connection_id) => {
        event.preventDefault();
        axiosInstance.post('accept-connection', { id:connection_id })
          .then(response => {
            // Handle successful response
            fetchMemberList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                console.log(error.connection_id); 
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };

      const calcelConnection = (event, connection_id,self) => {
        event.preventDefault();
        axiosInstance.post('cancel-connection-request', { id:connection_id,self })
          .then(response => {
            // Handle successful response
            fetchMemberList();
            showSuccessMsg(response.data.message);
          })
          .catch(errors => {
            if (errors) {
                let error = errors.response.data.errors;
                console.log(error.connection_id); 
                Object.keys(error).map((key, index) => {
                    return key !== "debug" ? showErrorMsg(error[key][0]) : '';
                });
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
          });
      };
    
        return (
            <>
                {connection_status === 'add_friend' && (
                    <a 
                        onClick={(event) => addMember(event,userId)} 
                        href="/" 
                        className="mt-0 btn  lh-20 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                    >ADD FRIEND
                    </a>
                )}
                {connection_status === 'request_sent' && ( 
                    <a 
                        onClick={(event) => calcelConnection(event,userId,true)} 
                        href="/" 
                        className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl"
                    >Cancel Request
                    </a>
                )}

                {connection_status === 'confirm' && ( <>
                        <a 
                            onClick={(event) => acceptConnection(event,userId)} 
                            href="/" 
                            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                        >Confirm
                        </a>
                        <a 
                            onClick={(event) => calcelConnection(event,userId,false)} 
                            href="/"
                            className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">
                            Delete
                        </a>
                        
                    </>
                )}

                {connection_status === 'connected' && ( <>
                        <a 
                            onClick={(event) => calcelConnection(event,userId,false)} 
                            href="/"
                            className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">
                            Delete
                        </a>
                        
                    </>
                )}
            </>
        
        );
    
}

export default UserActionButton;




