import React, { useState, useEffect, useCallback,Fragment } from "react";
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import axiosInstance from '../service/api';

import { useLocation } from "react-router-dom";
import UserActionButton from "../components/UserActionButton";

const Member = () => {
    const [memberList, setMemberList] = useState([]);
    const location = useLocation();
    

    const fetchMemberList = useCallback(async () => {
        try {
            const friends = location.pathname.includes('friends');
            const response = await axiosInstance.get('get-available-users',{
                params: {  friends  } 
            });
            setMemberList(response.data.data.data);
        } catch (error) {
            console.error('Error fetching member list', error);
        }
    }, [location.pathname]);

    

    useEffect(() => {
        fetchMemberList();
    }, [fetchMemberList]);

    
      

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Wowzer"/>

                                <div className="row ps-2 pe-2">
                                    {memberList.map((value, index) => (
                                        <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                    <Link to = {`user-details?userId=${value.id}`}>
                                                        <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w75 z-index-1">
                                                            <img src={value.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${value.profile_image}`:"assets/images/user.png"} alt="user" className="float-right p-0 bg-white rounded-circle w75 h75 shadow-xss" />
                                                        </figure>
                                                    </Link>
                                                    <div className="clearfix w-100"></div>
                                                    <Link to = {`user-details?userId=${value.id}`}>
                                                        <h4 className="fw-700 font-xsss mt-3 mb-0">
                                                            {value.name}
                                                        </h4>
                                                    </Link>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                                        <Link to = {`user-details?userId=${value.id}`}>{value.user}</Link>
                                                    </p>
                                                    <UserActionButton
                                                        connection_status = {value.connection_status}
                                                        userId = {value.id}
                                                        fetchMemberList={fetchMemberList} 
                                                          />
                                                    
                                                </div>
                                            </div>
                                        </div> 
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Popupchat />
            <Appfooter /> 
        </Fragment>
    );
}

export default Member;
