import React, { useEffect } from 'react';
import axiosInstance from '../service/api'; 
import { showSuccessMsg, showErrorMsg } from "../utils/notification";

const AuthCallback = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      // Send the authorization code to the backend
      axiosInstance.post('google-login', { code })
        .then(response => {

            if(response.data.status){

                showSuccessMsg(response.data.message);
                const { data:{auth:{access_token}} } = response.data;
                localStorage.setItem('authToken', access_token);
                window.location = '/travel-events'; 
            }

          // Handle success, e.g., store tokens or redirect
        })
        .catch(error => {
            if (error.response && error.response.data) {
                showErrorMsg(error.response.data.error);
            } else {
                showErrorMsg('An unexpected error occurred. Please try again.');
            }
            window.location = '/login'; 
        });
    }
  }, []);

  return <div>Loading...</div>;
};

export default AuthCallback;
