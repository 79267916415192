import React, {  Fragment } from "react";
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Contacts from "../components/Contacts";

const Chat = () => {
    
    const initialValues = {
        chat_message: '',
        
    };

    const userSignUpSchema = Yup.object().shape({
        chat_message: Yup.string()
            .min(2, 'Message must be at least 2 characters long')
            .max(50, 'Message must be less than 50 characters long')
            .required("Please enter message"),
        user_id: Yup.number(),
        
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        /* try {
            const response = await axios.post(`${API_BASE_URL}/auth/register`, values);
            showSuccessMsg(response.data.message);
            resetForm();
        } catch (errors) {
            if (errors.response && errors.response.data) {
                let error = errors.response.data.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
        } finally {
            setSubmitting(false);
        } */
    };



        return (
            <Fragment> 
                <Header />
                

                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            
                            <div className="row">
                                            
                                       

                                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    
                                    <div className="card-body p-3 w-100 border-0">
                                        
                                                <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                                    <div className="chat-body pe-2 ">
                                                        <div className="messages-content pb-1">
                                                            <div className="message-item">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM</div>
                                                                    </div>
                                                                </div>
                                                                <div className="message-wrap">I'm fine, how are you.</div>
                                                            </div>

                                                            <div className="message-item outgoing-message">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM<i className="ti-double-check text-info"></i></div>
                                                                    </div>
                                                                </div>
                                                                <div className="message-wrap">I want those files for you. I want you to send 1 PDF and 1 image file.</div>
                                                            </div>

                                                            <div className="message-item">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM</div>
                                                                    </div>
                                                                </div>
                                                                <div className="message-wrap">I've found some cool photos for our travel app.</div>
                                                            </div>

                                                            <div className="message-item outgoing-message">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM<i className="ti-double-check text-info"></i></div>
                                                                    </div>
                                                                </div>
                                                                <div className="message-wrap">Hey mate! How are things going ?</div>
                                                            </div>

                                                            <div className="message-item">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM</div>
                                                                    </div>
                                                                </div>
                                                                <figure>
                                                                    <img src="assets/images/product.png" className="w-25 img-fluid rounded-3" alt="avater" />
                                                                </figure>
                                                                
                                                            
                                                            </div>

                                                            <div className="message-item outgoing-message">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                        <img src="assets/images/user.png" alt="avater" />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>Byrom Guittet</h5>
                                                                        <div className="time">01:35 PM<i className="ti-double-check text-info"></i></div>
                                                                    </div>
                                                                </div>
                                                                <div className="message-wrap" >Hey mate! How are things going ?</div>

                                                            </div>
                                                            <div className="clearfix"></div>


                                                        </div>
                                                    </div>
                                                </div>
                                    <div className=" dark-bg  shadow-none theme-dark-bg" >
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={userSignUpSchema}
                                        onSubmit={handleSubmit}
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                    >
                                        {({ isSubmitting, errors }) => (
                                            <Form className="d-flex">
                                                 <div className="pt-1 text-center col-lg-1 form-group">
                                                    <i class="display1-size text-black feather-file-plus "></i>
                                                 </div>
                                                <div className="col-lg-10 form-group">
                                                    <Field
                                                        type="text"
                                                        placeholder="Start typing.."
                                                        name="chat_message"
                                                        className="chatFormInput w-100 rounded-xl"
                                                    />

                                                    <ErrorMessage
                                                        name="chat_message"
                                                        component="div"
                                                        className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                <div className="col-lg-1 text-right form-group">     
                                                   <button
                                                        type="submit"
                                                        className="chatFormButton bg-current"
                                                        disabled={isSubmitting}
                                                    >
                                                        
                                                        {isSubmitting ? (
                                                            <span className="spinner-border spinner-border-sm ml-2"></span>
                                                        ):<i className="ti-arrow-right text-white"></i>}
                                                    </button>
                                                
                                                </div>

                                                

                                                </Form>
                                )}
                            </Formik>
                                    </div> 



                                            </div>
                                            
                                        </div>


                                    </div>
                                    <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                        <Contacts />
                                    
                                    </div>
                                </div>
                                
                            
                        </div>
                        
                    </div>
                </div>


               
            </Fragment>
        );

}

export default Chat;