import React, {useContext} from 'react';


import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as Yup from "yup";

import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import axiosInstance from '../service/api';
import { UserContext } from '../context/UserContext';

const postCommentSchema = Yup.object().shape({
    comment_content: Yup.string()
      .min(1, "Description should at least have 1 character.")
      .required("Required")
  });
  const initComment = {
    'comment_content':''
  };
  

const CommentField = (props) => {
    const { user } = useContext(UserContext);

    const createCommentOnPost = async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
            values.post_id = props.postId; 
            values.parent_id = props.parentId; 
            const response = await axiosInstance.post('posts/create-comment', values);
            showSuccessMsg(response.data.message);
            resetForm();
            props.onCommentCreated(response.data.post);
        } catch (errors) {
            console.log(errors); 
            if (errors.response && errors.response.data) {
                let error = errors.response.data.error;
                Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              } else {
                showErrorMsg('An unexpected error occurred. Please try again.')
              }
        } finally {
            setSubmitting(false);
        }
    };

    


    const textArea = (props) => {
  
        let { form, field, className, placeholder } = props;
        return (
          <textarea
            className={className}
            rows="1"
            cols="30"
            placeholder={placeholder}
            name={field.name}
            onChange={(values) =>
              form.setFieldValue(field.name, values.target.value)
            }
            value={field.value}
            
          />
        );
      };

    return (
        <Formik
                    initialValues={initComment}
                    validationSchema={postCommentSchema}
                    onSubmit={createCommentOnPost} 
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                    <Form>

                        <div className="card-body d-flex p-0 mt-3 ">
                            <figure className="avatar  mx-2 mt-1 mb-2">
                                
                                <img 
                                    src={user && user.profile_image?`${process.env.REACT_APP_WEB_BASE_URL}${user.profile_image}`:"assets/images/user.png"} 
                                    alt="user" 
                                    className="shadow-sm rounded-circle w60 h60 " />
                            </figure>
                            <div className=" w-100">
                                <Field
                                    type="textarea"
                                    name="comment_content"
                                    component={textArea}
                                    placeholder={`Add a ${props.type}...`}
                                    className=" bor-0 h70 w-100 rounded-xxl p-2 ps-3 font-xssss  fw-500 border-light-md theme-dark-bg"
                                />
                                <ErrorMessage
                                    name="comment_content"
                                    component="div"
                                    className="text-danger"
                                />   
                            </div>
                        </div>

                        <div className="card-body d-flex p-0 mt-0">
                          
                            <div className="ms-auto pointer"  >
                                <button
                                    type="submit"
                                    className="border-0 p-2 lh-20 w100 bg-primary-gradiant text-white text-center font-xssss fw-600 ls-1 rounded-xl d-inline-block"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                    {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm ml-2"></span>
                                    )}
                                </button>

                                
                            </div>
                        </div>
                    </Form>
                )}
              </Formik>
    ); 
}

export default CommentField;