
import React, { useEffect, useState, useCallback } from 'react';
import Load from '../components/Load';
import Postview from '../components/Postview';
import { getPosts } from '../service/api';
import { useLocation } from "react-router-dom";
import Createpost from "../components/Createpost";

const ListPosts = () =>  {

    const location = useLocation();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [openCommentBox, setOpenCommentBox] = useState(null);

    const fetchPosts = useCallback(async () => {
        console.log("hello","fetch post"); 
        
        setLoading(true);
        try {
            const data = await getPosts(page, 10);

            setPosts((prevPosts) => [...prevPosts, ...data.data.data]);
            setHasMore(data.data.data.length > 0);
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setLoading(false);
        }
    }, [page]);

    // Function to update a specific post
    const updatePost = (updatedPost) => {
        console.log("hello","update post");
        const updatedPosts = posts.map(post =>
        post.id === updatedPost.id ? updatedPost : post
        );
        setPosts(updatedPosts);
    };

    useEffect(()=>{
        console.log("hello","location change");
        setPage(1);
        setPosts([]);
        //fetchPosts();
        //prevPathnameRef.current = location.pathname;
    },[location.pathname])

    useEffect(() => {
        fetchPosts();

        // eslint-disable-next-line
    }, [page,location.pathname]); 

    

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (hasMore && !loading) {
            console.log("Hello","handle scroll"); 
            setPage((prevPage) => prevPage + 1);
        }
    }, [hasMore, loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const toggleCommentBox = (postId) => {
        setOpenCommentBox(openCommentBox === postId ? null : postId);
    };

    const handlePostCreated = () => {
        setPosts([]);
        setPage(1);
        setHasMore(true);
        fetchPosts(); 
    };
    
        return (
            <>
                <Createpost onPostCreated={handlePostCreated} isCheckinModal={false} />
                {posts.map((post) => (
                    <Postview
                        key={post.id}
                        id={post.id}
                        postvideo={post.post_videos}
                        postimage={post.post_image}
                        avater={post.user_detail && post.user_detail.profile_image}
                        user={post.user_detail && post.user_detail.name}
                        time={post.created_at}
                        des={post.post_content}
                        user_id={post.user_id}
                        like_count={post.like_count}
                        love_count={post.love_count}
                        wow_count={post.wow_count}
                        comment_count={post.comment_count}
                        user_liked = {post.user_liked}
                        like_type = {post.like_type}
                        likers	= {post.likers}
                        updatePost={updatePost}
                        check_in_address={post.check_in_address}
                        openCommentBox={openCommentBox}
                        toggleCommentBox={toggleCommentBox}
                    />
                ))}

                {loading && <Load />}

                {!hasMore && <p>No more posts to load</p>}
            </>
        
        );
    
}

export default ListPosts;




